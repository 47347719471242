<template>
  <div class="background">
    <header>
      <Row class="header-content">
        <Logo />
        <FlexSpace />
        <AppearanceSwitch />
        <LanguageSelector :label="$t('Language')" />
        <LoginButton v-if="route.name !== 'up-next'" />
      </Row>
    </header>

    <main>
      <RouterView />
    </main>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { AppearanceSwitch, FlexSpace, Row } from '@justworkshr/alma'
import LanguageSelector from '@/components/LanguageSelector.vue'
import LoginButton from '@/components/LoginButton.vue'
import Logo from '@/components/Logo.vue'

const route = useRoute()
</script>

<style scoped>
.background {
  background-color: light-dark(#fffaf3, var(--color-navy));
}

header {
  background-color: var(--color-page-background);
}

.header-content,
main {
  width: 1280px;
  max-width: 100vw;
  margin: auto;
}

.header-content {
  padding-inline: var(--space-md);
  padding-block: var(--space-md);
  @media (width < 375px) {
    gap: 0;
  }
  @media (width >= 576px) {
    padding-inline: var(--space-2xl);
  }
}

main {
  min-height: 100vh;
  padding: var(--space-md);
  position: relative;
  @media (width >= 576px) {
    width: auto;
    padding: var(--space-2xl);
  }
}
</style>
